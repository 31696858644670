<template>
  <Register />
</template>

<script>
import Register from '../components/Register'

export default {
  name: 'RegisterView',

  components: {
    Register
  }
}
</script>
